import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import { colors, borderWidths, space } from '@entur/tokens';
import { TokenTable } from '~/components/TokensTable/TokenTable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
    </PageHeader>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@use '@entur/tokens/dist/styles.scss';

.card {
  transition: left $timings-slow ease-in-out;
}
`}</code></pre>
    <TokenTable tokenKey="timings" example={({
      value
    }) => {
      let [currBg, setCurrBg] = React.useState(colors.brand.white);
      return <div style={{
        display: 'block',
        border: `${borderWidths.default}px solid ${colors.misc.black}`,
        backgroundColor: `${currBg}`,
        transition: `${value} background linear`,
        padding: `${space.small}px ${space.default}px`
      }} onMouseOver={() => setCurrBg(colors.brand.lavender)} onMouseOut={() => setCurrBg(colors.brand.white)}>
        Hover for transisjonstid
      </div>;
    }} mdxType="TokenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      